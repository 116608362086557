import React from 'react'

import '@/assets/css/order_info.css'

const Component = () => {
    //接收跳转参数
    const [searchParams] = React.Router.useSearchParams()

    let [orderid, SetID] = React.useState(searchParams.get('orderid') ? searchParams.get('orderid') : 0)

    let [data, SetData] = React.useState({
        comment: '',
        rate: 5
    })

    let [order, SetOrder] = React.useState({
        status: '',
        price: '',
        starttime_text: '',
        endtime_text: "",
        startday_text: '',
        endday_text: '',
        order_day: '',
        room: {
            thumb_text: '',
            flag_text: [],
            name: '',
            id: 0,
        }
    })

    React.useEffect(() => {
        OrderData()
    }, [])

    // 
    const OrderData = async () => {
        var data = {
            busid: React.Business.id,
            orderid: orderid
        }

        var result = await React.HTTP.post('/order/info', data)

        if (result.code == 0) {
            React.error(result.msg)
            return false
        }

        SetOrder(result.data.order)

    }

    // 点击提交按钮
    const finish = async (value: any) => {
        value.orderid = orderid
        value.busid = React.Business.id


        // 发送请求
        var result = await React.HTTP.post('/order/comment', value)

        if (result.code == 0) {
            React.error(result.msg, () => { })
            return false
        } else {
            React.success(result.msg)
            return false
        }
    }


    return (
        <>
            <React.UI.NavBar
                style={{ background: `rgb(55, 68, 134)`, color: '#fff' }}
                back='返回'
                onBack={React.back}>
                订单评价
            </React.UI.NavBar>

            <div className="order_info">

                <React.UI.Form
                    name="form"
                    layout='horizontal'
                    mode='card'
                    onFinish={finish}
                    initialValues={data}
                    footer={
                        <React.UI.Button block type='submit' color='primary' size='large'>提交评论</React.UI.Button>
                    }
                >
                    <React.UI.Form.Item name="rate" label="评分" rules={[{ required: true }]}>
                        <React.UI.Rate />
                    </React.UI.Form.Item>

                    <React.UI.Form.Item name="comment" label="内容" rules={[{ required: true }]}>
                        <React.UI.TextArea placeholder="请输入内容" maxLength={100} rows={2} showCount />
                    </React.UI.Form.Item>
                </React.UI.Form>



                <div className="info_footer" onClick={() => React.navigate(`/room/info?rid=${order.room.id}`)}>
                    <div className="content_title">房型信息：</div>
                    <div className="detail">
                        <div className="thumb">
                            <img src={order.room.thumb_text} alt="" />
                        </div>
                        <div className="right">
                            <p>{order.room.name}</p>
                            <div className="tips">
                                {order.room.flag_text && order.room.flag_text.map((flag: any, index: any) => <span key={index}>{flag}</span>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <React.UI.Form
                name="comment"
                onFinish={comment}

                footer={
                    <React.UI.Button
                        style={{ background: `rgb(55, 68, 134)`, color: '#fff' }}
                        block type='submit' size='large'>
                        提交
                    </React.UI.Button>
                }
            >
                <React.UI.Form.Item
                    name='comment'
                    lobel='评价'
                    rules={[{ required: true, message: '评价内容不能为空' }]}
                >
                    <React.UI.TextArea
                        style={{ margin: '30px ' }}
                        placeholder='请输入评价内容' rows={5} />

                </React.UI.Form.Item>

            </React.UI.Form> */}
        </>
    )
}

export default Component
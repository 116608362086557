//路由模板加载标签
import { Outlet } from "react-router-dom";
import React from 'react';

import Info from '@/components/Coupon/Info'


//路由列表
const list = [
    {
        path: 'info',
        component: Info,
    }
]

//父组件结构
const Layout = () => <Outlet />


const RouterList = [
    {
        path: '/coupon',
        component: Layout,
        children: list
    }
]

export default RouterList
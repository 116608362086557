import React from 'react'

import '@/assets/css/collect.css'

import { HeartFill } from 'antd-mobile-icons'


const Component = () => {

    let [list, SetList] = React.useState([])
    let [page, SetPage] = React.useState(1)
    let [hasMore, SethasMore] = React.useState(true)

    // 上拉加载
    const loadMore = async () => {
        ListData()
    }

    // 下拉刷新
    const onRefresh = async () => {
        SetPage(1)
        SetList([])
        SethasMore(true)
        ListData()
    }

    React.useEffect(() => {
        ListData()
    }, [])

    // 请求列表数据
    const ListData = async () => {
        //  封装数据
        var data = {
            busid: React.Business.id,
            page: page,
        }

        // 发送请求
        var result = await React.HTTP.post('/collect/index', data)

        SethasMore(false)

        if (result.code == 0) {
            SethasMore(false)
            return false
        } else {
            SethasMore(true)
            SetPage(page + 1)
            SetList(list.concat(result.data))
        }

    }

    // 点击取消收藏
    const CancelCollect = async (roomid: any) => {

        // 组装数据
        var data = {
            busid: React.Business.id,
            roomid: roomid
        }

        // 发送请求
        var result = await React.HTTP.post('/collect/cancel', data)

        if (result.code == 0) {
            React.error(result.msg, () => { })
            return false
        } else {
            React.success(result.msg, () => { })
            onRefresh()
            return false
        }
    }





    return (
        <>
            <React.UI.NavBar
                style={{ background: `rgb(55, 68, 134)`, color: '#fff' }}
                back='返回'
                onBack={React.back}>
                收藏列表
            </React.UI.NavBar>

            <React.UI.PullToRefresh onRefresh={onRefresh}>
                <div className="collectlist">
                    {list.map((item: any, key) =>
                        <div className="item" key={key}>
                            <div className="collect">
                                <HeartFill fontSize={30} color='red' onClick={CancelCollect.bind(this, item.room.id)} />
                            </div>
                            <React.Router.Link to={`/room/info?rid=${item.room.id}`}>
                                <div className="images">
                                    <div className="swipers">
                                        <img src={item.room.thumb_text} alt="" />
                                    </div>
                                    <div className="title">{item.room.name}</div>
                                </div>
                                <div>
                                    <div className="item_bot">
                                        <div className="font">
                                            <span className="price">￥{item.room.price}/晚</span>
                                        </div>
                                        <div className="item_tips">收藏时间：{item.createtime_text}</div>
                                    </div>
                                </div>
                            </React.Router.Link>
                        </div>

                    )}

                </div>
            </React.UI.PullToRefresh>

            <React.UI.InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={0} />
        </>
    )
}


export default Component
import React from 'react'
import '@/assets/css/coupon-info.css'
import { Result } from 'antd-mobile'


const Component = () => {
    //接收跳转参数
    const [searchParams] = React.Router.useSearchParams()

    // 获取地址cid
    let [cid, SetCID] = React.useState(searchParams.get('cid') ? searchParams.get('cid') : 0)

    let [coupon, SetCoupon] = React.useState({
        title: '',
        rate: 1,
        total: 0,
        starttime_text: '',
        endtime_text: '',
        receive: true,
        thumb_text: '',


    })

    let [receive, SetReceive] = React.useState([])


    React.useEffect(() => {
        CouponData()
    }, [])


    // 请求优惠券详情
    const CouponData = async () => {
        // 组装数据
        var data = {
            cid: cid,
            busid: React.Business.id
        }

        var result = await React.HTTP.post("/coupon/info", data)

        if (result.code == 0) {
            React.error(result.msg)
            return false
        }

        SetCoupon(result.data.coupon)
        SetReceive(result.data.receive)
    }

    //  领取通告名单
    const Items = receive.map((item: any, key) =>
        <React.UI.Swiper.Item key={key}>
            <React.UI.NoticeBar

                style={{ 'border': '0px' }}
                content={`用户：${item.business.nickname ? item.business.nickname : '匿名用户'} 在${item.createtime_text} 领取了优惠券`}
                color='info'
            >
            </React.UI.NoticeBar>
        </React.UI.Swiper.Item>
    )


    // 点击领取按钮
    const CouponReceive = async () => {
        if (!React.Business.id) {
            React.error('请先登录', () => { })
            return false
        }

        // 组装数据
        var data = {
            cid: cid,
            busid: React.Business.id
        }

        // 发送请求
        var result = await React.HTTP.post('/coupon/receive', data)

        if (result.code == 0) {
            React.error(result.msg, () => { })
        } else {
            CouponData()
            React.success(result.msg, () => { })
        }
    }





    return (
        <>
            <React.UI.NavBar
                style={{ background: `rgb(55, 68, 134)`, color: '#fff' }}
                back='返回'
                onBack={React.back}
            >
                {coupon.title}
            </React.UI.NavBar>

            <React.UI.AutoCenter>
                <React.UI.Image src={coupon.thumb_text} width={'100%'} />
            </React.UI.AutoCenter>

            {/* 领取优惠券名单 */}
            <React.UI.Swiper
                autoplay
                loop
                direction='vertical'
                style={{ '--height': '40px', 'border': '0px' }}
                indicator={() => false}
            >
                {Items}
            </React.UI.Swiper>


            <div className="coupon_detail">
                <div className="coupon_info">
                    <div className="left">
                        <div className="left_top">
                            <div>
                                <span>{coupon.rate * 10}</span>折
                            </div>
                            <div className="top_info">
                                <div>优惠券</div>
                                <div>COUPON</div>
                            </div>
                        </div>
                    </div>
                    <div className="receive">
                        {
                            coupon.receive ?
                                <React.UI.Button size="small" disabled color='primary'>您已领取</React.UI.Button> :
                                <React.UI.Button size="small" color='primary' onClick={CouponReceive}>领取</React.UI.Button>
                        }
                    </div>
                </div>
                <div className="coupon_prompt">
                    <div className="prompt_title">温馨提示：</div>
                    <div><span>•</span>仅限量{coupon.total}张，赶快领取！</div>
                    <div><span>•</span>领取后{coupon.starttime_text}至{coupon.endtime_text}有效</div>
                </div>
            </div>
        </>
    )

}

export default Component
import React from 'react';
import Menu from '@/components/Common/Menu'

import '@/assets/css/index.css'
import '@/assets/css/swiper-bundle.min.css'

import { HeartFill } from 'antd-mobile-icons'

const Home = () => {
    let [list, SetList] = React.useState([])
    let [keywords, SetWords] = React.useState('')
    let [page, SetPage] = React.useState(1);
    let [room, SetRoom] = React.useState([]);
    let [hasMore, SethasMore] = React.useState(true)
    let ref = React.useRef(null)



    React.useEffect(() => {
        CouponData()
        // RoomData()
    }, [])

    const CouponData = async () => {
        var result = await React.HTTP.post('/coupon/index', {})

        if (result.code == 1) {
            SetList(result.data)
        }

    }

    // 优惠券组件
    const CouponItem = list.map((item: any, key) => {
        return (
            <React.UI.Swiper.Item key={key} onClick={() => React.navigate(`/coupon/info?cid=${item.id}`)}>
                <React.UI.Image src={item.thumb_text} width={'100%'} fit='fill' />
            </React.UI.Swiper.Item>
        )
    })

    // 搜索方法
    const search = async (e: any) => {
        // 阻止默认事件
        e.preventDefault();

        onRefresh();

        return false;
    }



    // 下拉刷新
    const onRefresh = async () => {
        SetPage(1)
        SetRoom([])
        SethasMore(true)
        RoomData()
    }

    // 上拉加载
    const loadMore = async () => {
        RoomData()
    }

    const RoomData = async () => {
        var result = await React.HTTP.post('/room/index', { page, keywords, busid: React.Business.id })

        SethasMore(false)

        if (result.code == 0) {
            SethasMore(false)
            return false
        } else {
            SethasMore(true);
            SetPage(page + 1)
            SetRoom(room.concat(result.data))
        }
    }

    // 点击收藏
    const Collect = async (roomid: any) => {
        // 判断当前是否登录
        if (!React.Business.id) {
            React.error('请先登录', () => { });
            return false
        }

        // 组装数据
        var data = {
            busid: React.Business.id,
            roomid: roomid
        }

        // 发送请求
        var result = await React.HTTP.post('/room/collect', data)

        if (result.code == 0) {
            React.error(result.msg, () => { })
            return false
        } else {
            React.success(result.msg, () => { })
            onRefresh()
        }

    }


    return (
        <>
            {/* 导航 */}
            <div className="top">
                <div>首页</div>
            </div>

            {/* 轮播图的优惠券 */}
            <React.UI.Swiper autoplay loop>
                {CouponItem}
            </React.UI.Swiper>

            <form className="hotel_search" onSubmit={search.bind(this)}>
                <input type="text" value={keywords} onChange={(e) => { SetWords(e.target.value) }} placeholder="请输入关键词搜索" />
                <div className="screen_icon">
                    <img src="./assets/images/screen.png" alt="" />
                </div>
            </form>

            <React.UI.PullToRefresh onRefresh={onRefresh}>
                <div className="hotellist">
                    {room.map((item: any, key) =>
                        <div className="item" key={key}>
                            <div className="collect">
                                {item.collect ?
                                    <HeartFill fontSize={30} color='red' onClick={Collect.bind(this, item.id)} /> :
                                    <HeartFill fontSize={30} color='#fff' onClick={Collect.bind(this, item.id)} />}
                            </div>
                            {/* <div className="type_name">新房特惠</div> */}

                            <React.Router.Link to={`/room/info?rid=${item.id}`}>
                                <div className="images">
                                    <div className="swipers">
                                        <img src={item.thumbs_text[0]} alt="" />
                                    </div>
                                    <div className="title">{item.name}</div>
                                </div>
                                <div>
                                    <div className="item_top">
                                        {item.flag_text && item.flag_text.map((flag: any, index: any) =>
                                            <span key={index}>{flag}</span>
                                        )}
                                    </div>
                                    <div className="item_bot">
                                        <div className="font">
                                            <span className="price">￥{item.price}/晚</span>
                                        </div>
                                        <div className="btn">立即预定</div>
                                    </div>
                                </div>
                            </React.Router.Link>
                        </div>
                    )}
                </div>
            </React.UI.PullToRefresh>
            <React.UI.InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={0} />



            {/* <div className="hotel_more">
                没有更多了
            </div> */}

            <Menu />
        </>
    )
}

export default Home
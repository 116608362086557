//路由模板加载标签
import { Outlet } from "react-router-dom";
import React from 'react';

import Index from '@/components/Collect/Index'

//路由列表
const list = [
    {
        path: 'index',
        component: Index,
        auth: true
    },
]

//父组件结构
const Layout = () => <Outlet />


const RouterList = [
    {
        path: '/collect',
        component: Layout,
        children: list
    }
]

export default RouterList
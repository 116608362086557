import React from 'react';
import Menu from '@/components/Common/Menu'

import '@/assets/css/user.css'

const Index = () => {
    let [allcount, SetAllCount] = React.useState(0)
    let [incount, SetInCount] = React.useState(0)
    let [comcount, SetComCount] = React.useState(0)


    // let [auth, SetAuth] = React.useState(<></>)

    // 邮箱认证显示
    // if (React.Business.auth == 0) {
    //     auth = (
    //         <div className="item">
    //             <React.Router.Link to="/business/email">
    //                 <div className="title">邮箱认证</div>
    //                 <div className="icon">
    //                     <img src="/assets/images/right.png" alt="" />
    //                 </div>
    //             </React.Router.Link>
    //         </div>
    //     )
    // }

    React.useEffect(() => {
        CountData()
    }, [])


    // 退出
    const logout = async () => {
        var result = await React.UI.Dialog.confirm({
            content: '是否确认退出'
        })

        if (result) {
            // 删除cookie
            React.Cookie.remove('business')

            //跳转
            React.navigate('/business/login')
        }
    }

    // 订单数量
    const CountData = async () => {
        var result = await React.HTTP.post('/order/count', { busid: React.Business.id })

        if (result.code == 1) {
            SetAllCount(result.data.allcount)
            SetInCount(result.data.incount)
            SetComCount(result.data.comcount)
        }


    }



    return (
        <>
            <div className="top">
                <div>个人中心</div>
            </div>
            {/* 头部 */}
            <div className="header">
                <div className="userinfo">
                    <div className="avatar">
                        <img src={React.Business.avatar_text} alt="" />
                    </div>
                    <div className="nickname">{React.Business.nickname}</div>
                </div>
                <div className="corrugated">
                    <div className="wave-top wave-item"></div>
                    <div className="wave-middle wave-item"></div>
                    <div className="wave-bottom wave-item"></div>
                </div>
            </div>
            <div className="menu-center">
                <div className="item">
                    <div>{allcount}</div>
                    <div className="text">全部订单</div>
                </div>
                <div className="item">
                    <div>{incount}</div>
                    <div className="text">待入住</div>
                </div>
                <div className="item">
                    <div>{comcount}</div>
                    <div className="text">待评论</div>
                </div>
            </div>
            <div className="menu-cell">
                <div className="item">
                    <React.Router.Link to="/business/profile">
                        <div className="title">个人资料</div>
                        <div className="icon">
                            <img src="/assets/images/right.png" alt="" />
                        </div>
                    </React.Router.Link>
                </div>

                {React.Business.auth == 0 ?
                    <div className="item">
                        <React.Router.Link to="/business/email">
                            <div className="title">邮箱认证</div>
                            <div className="icon">
                                <img src="/assets/images/right.png" alt="" />
                            </div>
                        </React.Router.Link>
                    </div> :
                    <></>}

                <div className="item">
                    <React.Router.Link to="/guest/index">
                        <div className="title">住客信息</div>
                        <div className="icon">
                            <img src="/assets/images/right.png" alt="" />
                        </div>
                    </React.Router.Link>
                </div>
                <div className="item">
                    <React.Router.Link to="/order/index">
                        <div className="title">房间订单</div>
                        <div className="icon">
                            <img src="/assets/images/right.png" alt="" />
                        </div>
                    </React.Router.Link>
                </div>
                <div className="item">
                    <React.Router.Link to="/collect/index">
                        <div className="title">我的收藏</div>
                        <div className="icon">
                            <img src="/assets/images/right.png" alt="" />
                        </div>
                    </React.Router.Link>
                </div>

                <div className="item">
                    <React.Router.Link to='/business/coupon'>
                        <div className="title">我的优惠券</div>
                        <div className="icon">
                            <img src="/assets/images/right.png" alt="" />
                        </div>
                    </React.Router.Link>
                </div>
                <div className="item">
                    <React.Router.Link to="/business/about">
                        <div className="title">关于我们</div>
                        <div className="icon">
                            <img src="/assets/images/right.png" alt="" />
                        </div>
                    </React.Router.Link>
                </div>
                <div className="item">
                    <a onClick={logout}>
                        <div className="title">退出登陆</div>
                        <div className="icon">
                            <img src="/assets/images/right.png" alt="" />
                        </div>
                    </a>
                </div>
            </div>

            <Menu />

        </>
    )
}

export default Index
import React from "react";
import { AppOutline, UserOutline } from 'antd-mobile-icons'

const tabs = [
    {
        url: '/',
        title: '首页',
        icon: <AppOutline />,
    },
    {
        url: '/business/index',
        title: '我的',
        icon: <UserOutline />,
    },
]


const Menu = () => {
    const setRouteActive = React.Router.useNavigate()
    const { pathname } = React.Router.useLocation()

    return (
        <React.UI.TabBar
            style={{
                background: '#fff', position: 'fixed', left: 0, right: 0, bottom: 0, zIndex: 999
            }}
            activeKey={pathname} onChange={(value: any) => setRouteActive(value)}>
            {
                tabs.map(item => (
                    <React.UI.TabBar.Item key={item.url} icon={item.icon} title={item.title} />
                ))
            }
        </React.UI.TabBar >
    )
}

export default Menu
import React from 'react'
import { Link } from 'react-router-dom'

import '@/assets/css/login.css'

const Register = () => {

    // 实例化钩子函数
    const navigate = React.Router.useNavigate()

    const register = async (values: any) => {
        var result: any = await React.HTTP.post('/business/register', values)


        if (result.code) {
            React.UI.Toast.show({
                icon: 'success',
                content: result.msg,
                afterClose: () => {
                    navigate('/business/login')
                    return false
                }
            })
        } else {
            React.UI.Toast.show({
                icon: 'fail',
                content: result.msg
            })

            return false
        }
    }


    return (
        <div className="login">
            <React.UI.Form
                initialValues={{}}
                onFinish={register}
                name="register"
                footer={
                    <React.UI.Button block type='submit' color='primary' size='large'>
                        注册
                    </React.UI.Button>
                }
            >
                <React.UI.Form.Item
                    name='mobile'
                    label='手机号'
                    rules={[{ required: true, message: '手机号不能为空' }, { pattern: /^1[3456789]\d{9}$/, message: '手机号格式不对' }]}
                >
                    <React.UI.Input placeholder='请输入手机号' />
                </React.UI.Form.Item>

                <React.UI.Form.Item
                    name='password'
                    label='密码'
                    rules={[{ required: true, message: '密码不能为空' }]}
                >
                    <React.UI.Input type="password" placeholder='请输入密码' />
                </React.UI.Form.Item>

            </React.UI.Form>

            <div className='action'>
                <Link className='text1' to="/business/login">立即登录 》</Link>
            </div>
        </div>
    )
}

export default Register

import React from 'react'
import AreaPicker from '@/components/Common/AreaPicker';

const Profile = () => {

    const [form] = React.UI.Form.useForm()

    // 地区显示或隐藏
    var [area, SetArea] = React.useState(false)

    // 组装表单数据
    var [bus, SetBus] = React.useState({
        nickname: React.Business.nickname,
        mobile: React.Business.mobile,
        password: React.Business.password,
        email: React.Business.email ? React.Business.email : '',
        gender: React.Business.gender,
        avatar: {},
        code: React.Business.district ? React.Business.district : null,
        region_text: React.Business.region_text ? React.Business.region_text : ''
    })

    var [avatar, SetAvatar] = React.useState([{
        url: React.Business.avatar_text
    }])

    const DelAvatar = () => {
        SetAvatar([])
    }

    const upload = async (file: any) => {
        React.Business.avatar = file

        return {
            url: URL.createObjectURL(file),
        }
    }

    const AreaConfirm = async (val: any, list: any) => {
        var code = ''

        for (var k = val.length; k >= 0; k--) {
            if (val[k]) {
                code = val[k].toString()
                break;
            }
        }

        var region_text = ''

        if (list[0]) region_text += `${list[0]}`
        if (list[1]) region_text += `-${list[1]}`
        if (list[2]) region_text += `-${list[2]}`


        form.setFieldsValue({
            region_text: region_text,
            code: code
        })
    }

    const profile = async (values: any) => {

        // 组装数据
        values.id = React.Business.id

        //  头像
        if (React.Business.avatar) {
            values.avatar = React.Business.avatar
        }

        if (form.getFieldValue('code')) {
            values.code = form.getFieldValue('code')
        }

        if (!values.password) delete values.password

        // 请求接口
        var result = await React.HTTP.upload('/business/profile', values)

        if (result.code == 0) {
            React.error(result.msg, () => { })
        } else {
            React.SetBusiness(result.data)
            React.Cookie.save('business', result.data);

            React.success(result.msg)
        }
    }


    return (
        <>
            <React.UI.NavBar
                style={{ background: `rgb(55, 68, 134)`, color: '#fff' }}
                back='返回'
                onBack={React.back}>
                个人资料
            </React.UI.NavBar>

            <AreaPicker visible={area} onClose={() => { SetArea(false) }} onConfirm={AreaConfirm} />


            <div className='profile'>
                <React.UI.Form
                    form={form}
                    name="form"
                    mode='card'
                    layout='horizontal'
                    initialValues={bus}
                    onFinish={profile}
                    footer={
                        <React.UI.Button
                            style={{ background: `rgb(55, 68, 134)`, color: '#fff' }}
                            block type='submit' size='large'>
                            提交
                        </React.UI.Button>
                    }
                >

                    {/* 文件上传 */}
                    <React.UI.AutoCenter style={{ margin: '30px 0px' }}>
                        <React.UI.ImageUploader value={avatar} maxCount={1} onDelete={DelAvatar} upload={upload} onChange={SetAvatar} />
                    </React.UI.AutoCenter>

                    {/* <React.UI.AutoCenter style={{ margin: '30px 0px' }}>
                        <React.UI.Form.Item name="avatar">
                            <React.UI.ImageUploader value={avatar} maxCount={1} onDelete={DelAvatar} upload={upload} onChange={SetAvatar} />
                        </React.UI.Form.Item>
                    </React.UI.AutoCenter> */}


                    <React.UI.Form.Item
                        name='nickname'
                        label='昵称'
                        rules={[{ required: true, message: '昵称不能为空' }]}
                    >
                        <React.UI.Input placeholder='请输入昵称' />
                    </React.UI.Form.Item>

                    <React.UI.Form.Item
                        name='mobile'
                        label='手机号'
                        rules={[{ required: true, message: '手机号不能为空' }, { pattern: /^1[3456789]\d{9}$/, message: '手机号格式不对' }]}
                    >
                        <React.UI.Input placeholder='请输入手机号' />
                    </React.UI.Form.Item>

                    <React.UI.Form.Item
                        name='email'
                        label='邮箱'
                        rules={[{ pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/, message: '邮箱格式不对' }]}
                    >
                        <React.UI.Input placeholder='请输入邮箱' />
                    </React.UI.Form.Item>

                    <React.UI.Form.Item
                        name='password'
                        label='密码'
                    >
                        <React.UI.Input type="password" placeholder='请输入密码' />
                    </React.UI.Form.Item>

                    <React.UI.Form.Item
                        name='gender'
                        label='性别'
                        rules={[{ required: true, message: '性别不能为空' }]}
                    >
                        <React.UI.Radio.Group>
                            <React.UI.Space direction="vertical">
                                <React.UI.Radio value='0'>保密</React.UI.Radio>
                                <React.UI.Radio value='1'>男</React.UI.Radio>
                                <React.UI.Radio value='2'>女</React.UI.Radio>
                            </React.UI.Space>
                        </React.UI.Radio.Group>
                    </React.UI.Form.Item>

                    <React.UI.Form.Item name="region_text" label="地区" onClick={() => SetArea(true)}>
                        <React.UI.Input placeholder="请选择地区" readOnly />
                    </React.UI.Form.Item>

                    <React.UI.Form.Header />


                </React.UI.Form>
            </div>
        </>
    )
}

export default Profile
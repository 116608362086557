import React from 'react'

import '@/assets/css/order.css'

const Component = () => {
    let [status, SetStatus] = React.useState('')
    let [list, SetList] = React.useState([])
    let [page, SetPage] = React.useState(1)
    let [hasMore, SethasMore] = React.useState(true)

    let statuslist = [
        { name: '全部', value: '' },
        { name: '已支付', value: '1' },
        { name: '已入住', value: '2' },
        { name: '已退房', value: '3' },
        { name: '已评价', value: '4' },
        { name: '申请退款', value: '-1' }
    ]

    //上拉加载
    const loadMore = async () => {
        ListData()
    }

    //下拉刷新
    const onRefresh = async () => {
        SetPage(1)
        SethasMore(true)
        SetList([])
        ListData()
    }

    // 点击选项卡切换
    let TabChange = (status: any) => {
        SetStatus(status)
        onRefresh()
    }

    // 请求列表数据
    const ListData = async () => {
        // 封装数据
        var data = {
            busid: React.Business.id,
            status: status,
            page: page
        }


        // 发送请求
        var result = await React.HTTP.post('/order/index', data)

        SethasMore(false)

        if (result.code == 1) {
            SethasMore(true)
            SetPage(page + 1)
            SetList(list.concat(result.data))
        } else {
            SethasMore(false)
            return false
        }
    }


    // 点击取消订单
    const Cancel = async (orderid: any) => {
        var result = await React.UI.Dialog.confirm({
            content: '是否确认退出'
        })

        if (!result) {
            return false
        }

        //  组装数据
        var data = {
            busid: React.Business.id,
            orderid: orderid,
        }

        // 发送请求
        var result = await React.HTTP.post('/order/cancel', data)


        if (result.code == 0) {
            React.error(result.msg, () => { })
            return false
        } else {
            React.success(result.msg, () => {
                onRefresh()
            })
        }




    }




    return (
        <>
            <React.UI.NavBar
                style={{ background: `rgb(55, 68, 134)`, color: '#fff' }}
                back='返回'
                onBack={React.back}>
                订单列表
            </React.UI.NavBar>

            <React.UI.Tabs defaultActiveKey={status} onChange={TabChange}>
                {statuslist.map((item =>
                    <React.UI.Tabs.Tab title={item.name} key={item.value} />
                ))}
            </React.UI.Tabs>

            <React.UI.PullToRefresh onRefresh={onRefresh} >
                <div className="order_list">
                    {list.map((item: any, key) =>
                        <div className="item" key={key}>
                            <div className="item_top">
                                <p>{item.room ? item.room.name : ''}</p>
                                <div className="top_tag">{item.status_text}</div>
                            </div>
                            <div className="house">
                                <div className="item_swipers">
                                    <img src={item.room.thumb_text} alt="" />
                                </div>
                                <div className="item_times">
                                    <div>
                                        <div>{item.starttime_text}</div>
                                        <div>{item.startday_text} 14:00</div>
                                    </div>
                                    <div>
                                        <div>共{item.order_day}晚</div>
                                        <div className="item_right">
                                            <img src="./assets/images/right1.png" alt="" />
                                        </div>
                                    </div>
                                    <div>
                                        <div>{item.endtime_text}</div>
                                        <div>{item.endday_text} 12:00</div>
                                    </div>
                                    <div>
                                        <div className="item_pay">订单总价</div>
                                        <div className="item_price">￥{item.price}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="item_bar">
                                {item.status == '1' && <React.UI.Button size="small" color="danger" onClick={Cancel.bind(this, item.id)}>取消订单</React.UI.Button>}
                                {item.status == '3' && <React.UI.Button size='small' color="success" onClick={() => React.navigate(`order/comment?orderid=${item.id}`)}>评价</React.UI.Button>}

                                <React.UI.Button
                                    style={{ background: `rgb(55, 68, 134)`, color: '#fff' }}
                                    size="small"
                                    onClick={() => React.navigate(`/order/info?orderid=${item.id}`)
                                    }>订单详情</React.UI.Button>
                            </div>
                        </div>
                    )}

                </div>
            </React.UI.PullToRefresh>

            <React.UI.InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={0} />

        </>
    )
}

export default Component
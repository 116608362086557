import React from 'react'

import '@/assets/css/coupon.css'


const Conponent = () => {

    let [status, SetStatus] = React.useState('')
    let [coupon, SetCoupon] = React.useState([])
    let [page, SetPage] = React.useState(1)
    let [hasMore, SethasMore] = React.useState(true)

    let statuslist = [
        { name: '全部', value: '' },
        { name: '已使用', value: '0' },
        { name: '未使用', value: '1' },
    ]

    // 上拉加载
    const loadMore = async () => {
        CouponData()
    }

    // 下拉刷新
    const onRefresh = async () => {
        SetPage(1)
        SethasMore(true)
        SetCoupon([])
        CouponData()
    }

    // 点击切换选项卡
    let TabChange = (status: any) => {
        SetStatus(status)
        onRefresh()
    }


    // 获取优惠券信息
    const CouponData = async () => {
        // 组装数据
        var data = {
            busid: React.Business.id,
            page: page,
            status: status
        }

        console.log(data);


        // 发送请求
        var result = await React.HTTP.post('/business/coupon', data)

        SethasMore(false)

        if (result.code == 1) {
            SethasMore(true)
            SetPage(page + 1)
            SetCoupon(coupon.concat(result.data))
        } else {
            SethasMore(false)
            return false
        }
    }





    return (
        <>
            <React.UI.NavBar
                style={{ background: `rgb(55, 68, 134)`, color: '#fff' }}
                back='返回'
                onBack={React.back}>
                我的优惠券
            </React.UI.NavBar>

            <React.UI.Tabs defaultActiveKey={status} onChange={TabChange}>
                {statuslist.map((item: any, key) =>
                    <React.UI.Tabs.Tab title={item.name} key={item.value} />
                )}
            </React.UI.Tabs>

            <React.UI.PullToRefresh onRefresh={onRefresh}>
                <div className="coupon_list">
                    {coupon.map((item: any, key) =>
                        <div className="coupon_item" key={key}>
                            <div className="item_content">
                                <a href="coupon-info.html">
                                    <div className="left">
                                        {item.coupon.rate * 10}<span>折</span>
                                    </div>
                                    <div className="right">
                                        <div>{item.coupon.title}</div>
                                        <div>订单打<span>{item.coupon.rate}折</span></div>
                                        <div>每人限领1张</div>
                                        <div>有效时间： {item.coupon.starttime_text}至{item.coupon.endtime_text}</div>
                                    </div>
                                </a>
                            </div>
                            <div className="item_btn">
                                {item.status == 1 ?
                                    <React.UI.Button
                                        className="right_btn"
                                        style={{ background: `rgb(55, 68, 134)`, color: '#fff' }}
                                    >未使用</React.UI.Button> :
                                    <React.UI.Button className="right_btn">已使用</React.UI.Button>
                                }
                            </div>
                        </div>
                    )}

                </div>
            </React.UI.PullToRefresh>

            <React.UI.InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={0} />

        </>
    )
}

export default Conponent
import React from 'react'

import '@/assets/css/hotel-info.css'
import '@/assets/css/swiper-bundle.min.css'

import { TMap, InfoWindow } from '@map-component/react-tmap';


const About = () => {

    const bannerList = [
        './assets/images/hotel1.jpg',
        './assets/images/hotel2.jpg',
        './assets/images/hotel3.jpg'
    ]

    return (
        <>
            <React.UI.NavBar
                style={{ background: `rgb(55, 68, 134)`, color: '#fff' }}
                back='返回'
                onBack={React.back}>
                门店详情
            </React.UI.NavBar>

            <React.UI.Swiper
                autoplay
                loop
            >
                {bannerList.map((item, index) => (
                    <React.UI.Swiper.Item key={index}>
                        <img src={item} alt="" />
                    </React.UI.Swiper.Item >
                ))}
            </React.UI.Swiper>

            <div className="hotelinfo" >
                <div className="hotelinfo_title">
                    创想第一门店
                </div>
                <div className="hotelinfo_address">
                    广东省深圳市福田区华强北街道振兴路341号上步工业区
                </div>


                <div className="map" id="map">
                    <TMap
                        mapKey="ZPMBZ-BWI3M-PP56L-66XNR-E7JYV-44B72"
                        version='1.exp'
                        center={{ lat: 39.984104, lng: 116.307503 }}
                    />
                </div>


                <div className="hotelinfo_desc">
                    <div className="desc_title">简介</div>
                    <div>这里是极速创想第一门店</div>
                </div>
                <div className="hotelinfo_desc">
                    <div className="desc_title">详情</div>
                    <div>这里是极速创想第一门店，欢迎你的预订入住</div>
                </div>
            </div >


        </>
    )

}

export default About;
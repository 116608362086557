import React from 'react'
// import '@/assets/css/email.css'

const Email = () => {

    let [btn, SetBtn] = React.useState('获取验证码')

    //  点击 发送验证码
    const send = async () => {

        SetBtn('60');
        let num = 60;

        // 组装数据
        var data = {
            busid: React.Business.id,
            email: React.Business.email,
            action: 'send'
        }

        // 发送请求
        var result = await React.HTTP.post('/business/email', data)

        if (result.code == 0) {
            React.error(result.msg, () => { })
        } else {
            React.SetBusiness(result.data)
            React.Cookie.save('business', result.data);

            React.success(result.msg)
        }




        var autoplay = () => {
            if (num <= 0) {
                // 结束倒计时
                SetBtn('重新发送验证码')
                clearInterval(T)
            } else {
                num = num - 1

                // 减1操作
                SetBtn(String(num))
            }
        }

        // 设置定时器
        var T = setInterval(autoplay, 1000)
    }


    //  点击提交
    const email = async (values: any) => {

        // console.log(values);

        // 组装数据
        var data = {
            busid: React.Business.id,
            email: values.emaiil,
            code: values.code,
        }

        // 发送请求
        var result = await React.HTTP.post('/business/email', data)

        if (result.code == 0) {
            React.error(result.msg, () => { })
        } else {
            React.success(result.msg)
        }





    }

    return (
        <>
            <React.UI.NavBar
                style={{ background: `rgb(55, 68, 134)`, color: '#fff' }}
                back='返回'
                onBack={React.back}>
                邮箱认证
            </React.UI.NavBar>

            {/* 头部头像 */}
            <div className="header">
                <div className="userinfo">
                    <div className="avatar">
                        <img src={React.Business.avatar_text} alt="" />
                    </div>
                    <div className="nickname">{React.Business.nickname}</div>
                </div>
            </div>

            <React.UI.Form
                name="email"
                onFinish={email}
                layout='horizontal'
                initialValues={React.Business}
                footer={
                    <React.UI.Button
                        style={{ background: `rgb(55, 68, 134)`, color: '#fff' }}
                        block type='submit' size='large'>
                        提交
                    </React.UI.Button>
                }
            >

                <React.UI.Form.Item
                    name='email'
                    label='邮箱'
                    rules={[{ required: true, message: '邮箱不能为空' }, { pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/, message: '邮箱格式不对' }]}
                >
                    <React.UI.Input placeholder='请输入邮箱' readOnly />
                </React.UI.Form.Item>

                <React.UI.Form.Item
                    name='code'
                    label='邮箱验证码'
                    extra={<a onClick={send}>{btn}</a>}
                    rules={[{ required: true, message: '邮箱验证码不能为空' }]}
                >
                    <React.UI.Input placeholder='请输入邮箱验证码' />
                </React.UI.Form.Item>




            </React.UI.Form>
        </>
    )
}

export default Email


//路由模板加载标签
import { Outlet } from "react-router-dom";
import React from 'react';

import Index from '@/components/Business/Index'
import Register from "@/components/Business/Register";
import Login from "@/components/Business/Login";
import Profile from "@/components/Business/Profile";
import About from "@/components/Business/About";
import Email from "@/components/Business/Email"
import Coupon from "@/components/Business/Coupon"



//路由列表
const list = [
    {
        path: 'index',
        component: Index,
        auth: true
    },
    {
        path: 'register',
        component: Register,
    },
    {
        path: 'login',
        component: Login,
    },
    {
        path: 'profile',
        component: Profile,
        auth: true
    },
    {
        path: 'about',
        component: About,
    },
    {
        path: 'email',
        component: Email,
        auth: true
    },
    {
        path: 'coupon',
        component: Coupon,
        auth: true
    },
]

//父组件结构
const Layout = () => <Outlet />


const RouterList = [
    {
        path: '/business',
        component: Layout,
        children: list
    }
]

export default RouterList
import React from "react";

import '@/assets/css/detail.css'

import { TMap, InfoWindow } from '@map-component/react-tmap';



const Conponent = () => {

    // 接收跳转参数
    const [searchParams] = React.Router.useSearchParams()

    let [rid, SetRID] = React.useState(searchParams.get('rid') ? searchParams.get('rid') : 0)

    let [comment, SetComment] = React.useState([])

    let [room, SetRoom] = React.useState({
        thumb_text: '',
        name: '',
        flag_text: [],
        content: '',
        total: 0,
        price: '',
        state: true,
        thumbs_text: []
    })

    React.useEffect(() => {
        RoomData()
        CommentData()
    }, [])

    //  请求详情
    const RoomData = async () => {
        var result = await React.HTTP.post('/room/info', { rid })

        if (result.code == 0) {
            React.error(result.msg)
            return false
        }

        SetRoom(result.data)
    }

    // 优惠券组件
    const RoomItem = room.thumbs_text.map((item: any, key) => {
        return (
            <React.UI.Swiper.Item key={key} onClick={() => React.navigate(`/coupon/info?cid=${item.id}`)}>
                <React.UI.Image src={item} width={'100%'} fit='fill' />
            </React.UI.Swiper.Item>
        )
    })

    // 请求评价数据
    const CommentData = async () => {
        var result = await React.HTTP.post('/room/comment', { roomid: rid })

        if (result.code == 1) {
            SetComment(result.data)
        }
    }

    const confirm = () => {
        if (!React.Business.id) {
            React.error('请先登录', () => { });
            return false
        }

        React.navigate(`/room/confirm?rid=${rid}`)
    }

    return (
        <>
            <React.UI.NavBar
                style={{ background: `rgb(55, 68, 134)`, color: '#fff' }}
                back='返回'
                onBack={React.back}
            >
                {room.name}
            </React.UI.NavBar>

            {/* 轮播图的房间 */}
            <React.UI.Swiper autoplay loop>
                {RoomItem}
            </React.UI.Swiper>

            {/* {room.thumb_text ? <React.UI.Image src={room.thumb_text} width={'100%'} fit="fill" /> : ''} */}


            <div className="detail_top">
                <div id="intro" className="intro">
                    <div className="title">{room.name}</div>
                    <div className="betwee">
                        <div className="left">
                            {room.flag_text && room.flag_text.map((flag: any, index: any) =>
                                <span key={index}>{flag}</span>
                            )}
                        </div>
                        <div className="right">分享</div>
                    </div>
                    <div className="title">{room.content}</div>
                </div>

                <div id="comment" className="comment">
                    <div className="title">评价</div>
                    <React.UI.List>
                        {comment.map((item: any, key) =>
                            <React.UI.List.Item
                                key={key}
                                prefix={
                                    <React.UI.Image
                                        src={item.business.avatar_text}
                                        style={{ borderRadius: 20 }}
                                        fit='cover'
                                        width={40}
                                        height={40}
                                    />
                                }
                                description={item.comment}


                            >
                                {item.business.nickname}
                                <React.UI.Rate style={{ '--star-size': '12px', position: 'absolute', right: '0px' }} value={item.rate} readOnly />
                            </React.UI.List.Item>
                        )}

                    </React.UI.List>




                </div>

                <div className="comment">
                    <div className="title">地图路线</div>
                    <TMap
                        mapKey="ZPMBZ-BWI3M-PP56L-66XNR-E7JYV-44B72"
                        version='1.exp'
                        center={{ lat: 39.984104, lng: 116.307503 }}
                    />
                </div>



                {/* 设施服务 */}
                {/* <div id="facility" className="facility">
                    <div className="title">设施服务</div>
                    <div className="plan">
                        <div className="item">
                            <img src="./assets/images/wrong.png" alt="" />
                            <span>WIFI</span>
                        </div>
                        <div className="item">
                            <img src="./assets/images/answer.png" alt="" />
                            <span>吹风机</span>
                        </div>
                        <div className="item">
                            <img src="./assets/images/wrong.png" alt="" />
                            <span>空调</span>
                        </div>
                        <div className="item">
                            <img src="./assets/images/answer.png" alt="" />
                            <span>有浴缸</span>
                        </div>
                    </div>
                </div> */}
                <div className="notesin">
                    <div className="title">预订须知</div>
                    <div className="item">
                        <span className="tips">预订房型：</span>
                        <span>{room.name}</span>
                    </div>
                    <div className="item">
                        <span className="tips">入离时间：</span>
                        <span>14:00 后入住，12:00 前退房</span>
                    </div>
                    <div className="item">
                        <span className="tips">预订时长：</span>
                        <span>随时可预订,最少预订1天, 最多预订天数不限</span>
                    </div>
                    <div className="item">
                        <span className="tips">房间数量：</span>
                        <span>{room.total}</span>
                    </div>
                </div>
            </div>

            <div className="foot-bar">
                <div className="price">
                    ￥{room.price}
                </div>
                <React.UI.Button
                    style={{ background: `rgb(55, 68, 134)`, color: '#fff' }}
                    disabled={room.state ? false : true}
                    onClick={confirm}
                >
                    立即预定
                </React.UI.Button>
            </div>

        </>
    )
}

export default Conponent
import React from 'react';
import { AddOutline } from 'antd-mobile-icons'
import res from 'antd-mobile-icons/es/AaOutline';

const Guest = () => {

    let [page, SetPage] = React.useState(1)
    let [list, SetList] = React.useState([])
    let [hasMore, SethasMore] = React.useState(true)
    let ref = React.useRef(null)

    //  下拉刷新
    const onRefresh = async () => {
        SetPage(1)
        SetList([])
        SethasMore(true)
        ListData()
    }

    // 上拉加载
    const loadMore = async () => {
        ListData()
    }

    const ListData = async () => {
        // 组装数据
        var data = {
            page: page,
            busid: React.Business.id
        }

        var result = await React.HTTP.post('/guest/index', data)

        if (result.code == 0) {
            // 没有更多数据
            SethasMore(false)
            return false
        }

        SetPage(page + 1)
        SetList(list.concat(result.data))
    }

    const ClickGuest = async (gid: any) => {
        // 弹框组件
        var result = await React.UI.Dialog.confirm({
            content: '是否确认退出'
        })

        if (!result) return false

        var result = await React.HTTP.post('/guest/del', { id: gid, busid: React.Business.id })

        if (result.code == 0) {
            React.error(result.msg, () => { })
            return false
        } else {
            React.success(result.msg, () => {
                onRefresh()
            })
            return false
        }
    }

    // 封装右侧滑动按钮数据
    const right = [
        {
            key: 'delete',
            text: '删除信息',
            color: 'danger',
        }
    ]

    return (
        <>
            <React.UI.NavBar
                style={{ background: `rgb(55, 68, 134)`, color: '#fff' }}
                back='返回'
                right={(<AddOutline fontSize={30} onClick={() => React.navigate('/guest/add')} />)}
                onBack={React.back}>
                住客列表
            </React.UI.NavBar>

            <React.UI.PullToRefresh onRefresh={onRefresh}>
                <React.UI.List>
                    {list.map((item: any, key) => (
                        <React.UI.SwipeAction ref={ref} key={key} rightActions={right} onAction={ClickGuest.bind(this, item.id)}>
                            <React.UI.List.Item key={key} description={item.mobile} onClick={() => React.navigate(`guest/edit?id=${item.id}`)}>
                                {item.nickname}({item.gender_text})
                            </React.UI.List.Item>
                        </React.UI.SwipeAction>

                    ))}
                </React.UI.List>

                <React.UI.InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={0} />
            </React.UI.PullToRefresh>


        </>
    )
}

export default Guest
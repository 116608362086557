import React from 'react'

import '@/assets/css/order_info.css'

const Component = () => {

    //接收跳转参数
    const [searchParams] = React.Router.useSearchParams()

    let [orderid, SetID] = React.useState(searchParams.get('orderid') ? searchParams.get('orderid') : 0)

    let [order, SetOrder] = React.useState({
        status: '',
        price: '',
        starttime_text: '',
        endtime_text: "",
        startday_text: '',
        endday_text: '',
        order_day: '',
        comment: '',
        rate: 0,
        room: {
            thumb_text: '',
            flag_text: [],
            name: '',
            id: 0,
        }
    })

    let [guest, SetGuest] = React.useState({
        nickname: '',
        mobile: '',
    })


    React.useEffect(() => {
        OrderData()
    }, [])

    // 请求详情
    const OrderData = async () => {
        var data = {
            busid: React.Business.id,
            orderid: orderid
        }

        console.log(data);


        var result = await React.HTTP.post('/order/info', data)

        if (result.code == 0) {
            React.error(result.msg)
            return false
        }

        console.log(result.data);


        SetOrder(result.data.order)
        SetGuest(result.data.guest)
    }

    const Comment = () => {
        if (order.status == '3') {
            return (
                <div className="info_title">
                    <h3>待评价</h3>
                    <p>感谢您的光临，请给我们打个分吧</p>
                    <React.UI.Button className="info_rate" onClick={() => React.navigate(`/order/comment?orderid=${orderid}`)}>立即评价</React.UI.Button>
                </div>
            )
        } else {
            return (<></>)
        }
    }

    const CommentPreview = () => {
        if (order.status == '4' && order.comment != '') {
            return (
                <>
                    <div className="content_item">
                        <p>星级评价</p>
                        <div>
                            <React.UI.Rate value={order.rate} readOnly />
                        </div>
                    </div>
                    <div className="content_item">
                        <p>评价内容</p>
                        <div>{order.comment}</div>
                    </div>

                </>
            )
        } else {
            return (<></>)
        }
    }





    return (
        <>
            <React.UI.NavBar
                style={{ background: `rgb(55, 68, 134)`, color: '#fff' }}
                back='返回'
                onBack={React.back}>
                订单详情
            </React.UI.NavBar>


            <div className="order_info">

                {Comment()}

                <div className="info_content">
                    <div className="content_title">订单信息：</div>
                    <div className="content_item">
                        <p>订单金额</p>
                        <div className="content_price">￥{order.price}</div>
                    </div>
                    <div className="content_item">
                        <p>开始时间</p>
                        <div>{order.starttime_text}{order.startday_text}</div>
                    </div>
                    <div className="content_item">
                        <p>结束时间</p>
                        <div>{order.endtime_text}{order.endday_text}</div>
                    </div>
                    <div className="content_item">
                        <p>入住人</p>
                        <div>{guest.nickname}</div>
                    </div>
                    <div className="content_item">
                        <p>联系电话</p>
                        <div>{guest.mobile}</div>
                    </div>
                    <div className="content_item">
                        <p>入住时间</p>
                        <div>{order.starttime_text} - {order.endtime_text} 共 {order.order_day} 晚</div>
                    </div>

                    {CommentPreview()}
                </div>
                <div className="info_footer">
                    <div className="content_title">房屋信息：</div>
                    <div className="detail">
                        <div className="thumb">
                            <img src={order.room.thumb_text} alt="" />
                        </div>
                        <div className="right">
                            <p>{order.room.name}</p>
                            <div className="tips">
                                {order.room.flag_text && order.room.flag_text.map((flag: any, index: any) => <span key={index}>{flag}</span>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Component